import { Position } from "../graphics";
import { canvas, running } from "../canvas.js";
import { key, mouse } from "../inputHandler";

class Cluster {

    static margin = 10;

    direction = 1;
    velocity = 1;
    moveForward = 0;
    bulletCooldown = {t: 0, threshold: 60};
    invaders = [];


    constructor(position, w_i, h_i) {
        this.position = position;
        this.w_i = w_i;
        this.h_i = h_i;
        this.howMany = w_i * h_i;
        this.width = (Cluster.margin + Invader.size) * w_i - Cluster.margin;
        this.height = (Cluster.margin + Invader.size) * h_i - Cluster.margin;

        for(let i = 0, k = -1; i < w_i; ++i, h_i % 2 == 0 ? k *= -1 : null)
            for(let q = 0; q < h_i; ++q, k *= -1)
                k == -1 ? this.invaders.push(new RedInvader(this.position, i, q)) : this.invaders.push(new GreenInvader(this.position, i, q));
            
        this.last = this.invaders.length - 1;
        this.first = 0;
        this.front = this.invaders.length - 1;
    }

    findShot() {
        
        for(let q = 0; q < 10; ++q) {

            let k = Math.floor(Math.random() * (this.w_i - 1)) * this.h_i + this.h_i - 1;
    
            for(let i = 0; i < this.h_i; ++i)
                if(this.invaders[k - i]) {
                    return k - i;
                }
        }
    }

    reloadWeapons() {

        this.bulletCooldown.t++;

        if(this.bulletCooldown.t > this.bulletCooldown.threshold) {

            let k = this.findShot()
            if(k)
                GameHandler.object.invaderBullets.push(new InvaderBullet(new Position(this.invaders[k].position.x + Invader.size/2, this.invaders[k].position.y + Invader.size), this.invaders[k].getColor()));
            this.bulletCooldown.t = 0;
        }
        
    }

    tick(dt) {
        this.reloadWeapons();
        let last = this.getLast();
        if(last.position.x > canvas.width - Invader.size) {

            this.position.x -= last.position.x + Invader.size - canvas.width;
            this.direction = -1;
            this.moveForward += Cluster.margin + Invader.size;

        }
        else {
            let first = this.getFirst();
            if(first.position.x < 0) {
                this.position.x -= first.position.x;
                this.direction = 1;
                this.moveForward = 30;
            }
        }


        if(this.getFront().position.y + Invader.size > canvas.height) {
            GameHandler.object.running = false;
            GameHandler.object.complete = false;
        }

        for(let i = 0; i < GameHandler.object.bullets.length; ++i) {
            let bullet = GameHandler.object.bullets[i];

            let x = Math.floor(this.w_i * (bullet.position.x - this.position.x)/this.width);
            let y = Math.floor(this.h_i * (bullet.position.y - this.position.y)/this.height);
    
            if(x >= 0 && x < this.w_i && y >= 0 && y < this.h_i && this.invaders[x * this.h_i + y]?.collision(bullet.position)) {
    
                let invader = this.invaders[x * this.h_i + y];
                invader.getMatrix().forEach((m, i) => {
                    if(m)
                        GameHandler.object.particles.push(new Particle(new Position(invader.position.x + (i % 7) * Invader.pixelSize, invader.position.y + Math.floor(i / 7) * Invader.pixelSize), invader.getColor()));
                });
                this.invaders[x * this.h_i + y] = undefined;
                GameHandler.object.bullets.splice(i, 1);


                --i;
                this.velocity += .03;

                GameHandler.object.score += 100;
                if(GameHandler.object.heightScore < GameHandler.object.score)
                    GameHandler.object.heightScore = GameHandler.object.score
                this.setBorders();
                --this.howMany;

                if(this.howMany == 0) {
                    GameHandler.object.running = false;
                    GameHandler.object.complete = true;
                }
            }
        }

        this.invaders.forEach(invader => {
            if(invader) {
                invader.position.x = this.position.x + invader.dx * (Cluster.margin + Invader.size)
                invader.position.y = this.position.y + invader.dy * (Cluster.margin + Invader.size);
            }
        });

        this.move(dt);
    }

    move(dt) {
        
        if(this.moveForward > 0) {
            this.moveForward -= this.velocity * dt;
            this.position.y += this.velocity * dt;
        }
        else {
            this.position.x += this.direction * this.velocity * dt;
        }
    }

    draw(g) {
        this.invaders.forEach(invader => invader?.draw(g));
    }

    setBorders() {

        if(!this.getLast()) {
            for(let i = this.last - 1; i >= 0; --i)
                if(this.invaders[i]) {
                    this.last = i;
                    break;
                }
        }
        
        if(!this.getFirst()) {
            for(let i = this.first + 1; i < this.invaders.length; ++i)
                if(this.invaders[i]) {
                    this.first = i;
                    break;
                }
        }

        if(!this.getFront()) {

            for(let i = this.h_i - 1; i >= 0; --i) {
                for(let q = this.w_i - 1; q >= 0; --q) {
                    if(this.invaders[i + q * this.h_i]) {
                        this.front = i + q * this.h_i;
                        return;
                    }
                }
            }
        }
    }

    getLast() {
        return this.invaders[this.last];
    }

    getFirst() {
        return this.invaders[this.first];
    }

    getFront() {
        return this.invaders[this.front];
    }
}

class Invader {

    static size = 30;
    static pixelSize = Invader.size/7;
    static w = 7;
    static h = 5;
    constructor(position, dx, dy) {
        this.dx = dx;
        this.dy = dy;
        this.position = new Position(position.x + dx * (Invader.size + Cluster.margin), position.y + dy * (Invader.size + Cluster.margin));
    }

    draw(g) {

        g.ctx.beginPath();
        g.ctx.fillStyle = this.getColor();
        this.getMatrix().forEach((m, i) => {
            if(m) {

                let x = this.position.x + (i % Invader.w) * Invader.pixelSize;
                let y = this.position.y + Math.floor(i / Invader.w) * Invader.pixelSize;
                g.ctx.rect(x, y, Invader.pixelSize, Invader.pixelSize);
            }
        });

        g.ctx.fill();
    }

    collision(position) {

        let x = Math.floor(Invader.w * (position.x - this.position.x)/Invader.size);
        let y = Math.floor(Invader.w * (position.y - this.position.y)/Invader.size);

        if(x >= 0 && x < Invader.w && y >= 0 && y < Invader.h && this.getMatrix()[x + y * Invader.w])
            return true;

        return false;
    }
}

class RedInvader extends Invader {

    static color = "rgb(220, 43, 7, 1)";
    static matrix = [
        0, 0, 1, 1, 1, 0, 0,
        0, 1, 0, 1, 0, 1, 0,
        1, 0, 1, 1, 1, 0, 1,
        0, 0, 1, 0, 1, 0, 0,
        0, 0, 0, 1, 0, 0, 0
    ];

    constructor(position, dx, dy) {
        super(position, dx, dy);
    }

    getMatrix() {
        return RedInvader.matrix;
    }
    
    getColor() {
        return RedInvader.color;
    }
}


class GreenInvader extends Invader {

    static color = "rgb(145, 252, 57, 1)";
    static matrix = [
        0, 0, 1, 1, 1, 0, 0,
        0, 1, 0, 1, 0, 1, 0,
        0, 1, 1, 1, 1, 1, 0,
        0, 0, 1, 0, 1, 0, 0,
        0, 1, 0, 0, 0, 1, 0
    ];

    constructor(position, dx, dy) {
        super(position, dx, dy);
    }

    getMatrix() {
        return GreenInvader.matrix;
    }
    
    getColor() {
        return GreenInvader.color;
    }
}

class Star {

    position = new Position(canvas.width * Math.random(), canvas.height * Math.random());
    size = Math.random() * 3 + 3;
    alpha = Math.random();
    fadeSpeed = Math.random() * .005 + .005;
    direction = 1;

    tick(dt) {
        this.alpha += this.fadeSpeed * this.direction * dt;

        if(this.alpha > 1) {
            this.alpha = 2 - this.alpha;
            this.direction = -1;
        }
        else if(this.alpha < 0) {
            this.alpha *= -1;
            this.direction = 1;
        }
    }

    draw(g) {
        g.ctx.beginPath();
        g.ctx.moveTo(this.position.x - this.size, this.position.y);
        g.ctx.lineTo(this.position.x, this.position.y - this.size);
        g.ctx.lineTo(this.position.x + this.size, this.position.y);
        g.ctx.lineTo(this.position.x, this.position.y + this.size);
        g.ctx.fillStyle = g.rgb(250, 250, 250, this.alpha);
        g.ctx.fill();
    }
}

class Bullet {

    static speed = 5;
    static color = "rgb(244, 254, 57, 1)";
    constructor(position) {
        this.position = position;
    }

    tick(dt) {
        this.position.y -= Bullet.speed * dt;
        if(this.position.y < 0)
            GameHandler.object.bullets.splice(this, 1);
            
    }

    draw(g) {
        g.line(this.position, this.position.add(0, 15), Bullet.color);
    }
}

class InvaderBullet {

    static speed = 3;
    constructor(position, color) {
        this.position = position;
        this.color = color;
    }

    tick(dt) {
        this.position.y += InvaderBullet.speed * dt;
        if(this.position.y > canvas.height)
        GameHandler.object.invaderBullets.splice(this, 1);
    }

    draw(g) {
        g.line(this.position, this.position.add(0, -10), this.color, 7);
        g.triangle(this.position.add(-8, 0),
        this.position.add(0, 8),
        this.position.add(8, 0), this.color);
        g.line(this.position.add(0, 1), this.position.add(0, -9), "white", 4);
        g.triangle(this.position.add(-5, 1),
        this.position.add(0, 6),
        this.position.add(5, 1), "white");
    }
}


class Player {

    position = new Position(canvas.width/2, canvas.height);
    size = 20;
    velocity = 4;
    maxShots = 3;
    constructor() {

    }

    draw(g) {

        Player.drawShip(g, this.position, this.size);
    }

    moveRight(dt) {
        this.position.x += this.velocity * dt;
        if(this.position.x > canvas.width - this.size)
            this.position.x = canvas.width - this.size;
    }

    moveLeft(dt) {
        this.position.x -= this.velocity * dt;
        if(this.position.x < this.size)
            this.position.x = this.size;
    }

    static drawShip(g, position, size) {

        g.ctx.beginPath();
        g.ctx.moveTo(position.x - size, position.y);
        g.ctx.lineTo(position.x - size, position.y - size * .6);
        g.ctx.lineTo(position.x - size * .714, position.y - size * .6);
        g.ctx.lineTo(position.x - size * .714, position.y - size * .8);
        g.ctx.lineTo(position.x - size * .143, position.y - size * .8);
        g.ctx.lineTo(position.x - size * .143, position.y - size);
        g.ctx.lineTo(position.x + size * .143, position.y - size);

        g.ctx.lineTo(position.x + size * .143, position.y - size);
        g.ctx.lineTo(position.x + size * .143, position.y - size * .8);
        g.ctx.lineTo(position.x + size * .714, position.y - size * .8);
        g.ctx.lineTo(position.x + size * .714, position.y - size * .6);
        g.ctx.lineTo(position.x + size, position.y - size * .6);
        g.ctx.lineTo(position.x + size, position.y);

        g.ctx.fillStyle = "rgb(120, 208, 253, 1)";
        g.ctx.fill();
    }
}

class GameHandler {
    static object;
    player = new Player();
    cluster;
    stars = [];
    invaderBullets = [];
    bullets = [];
    particles = [];

    running = true;
    complete = false;
    
    score = 0;
    lives = 3;
    level = 1;
    heightScore = 0;
    wave_h = 2;
    wave_w = 2;

    constructor() {

        for(let i = 0; i < 40; ++i)
            this.stars.push(new Star());

        this.cluster = new Cluster(new Position(0, 70), this.wave_w, this.wave_h);
        
    }

    tick(dt) {

        if(this.running) {
            this.stars.forEach(star => star.tick(dt));
            this.cluster.tick(dt);
            this.bullets.forEach(bullet => bullet.tick(dt));
            this.invaderBullets.forEach(bullet => bullet.tick(dt));
            this.particles.forEach(particle => particle.tick(dt));

            
            if(key.d == "keydown") {
                this.player.moveRight(dt);
            }

            if(key.a == "keydown") {
                this.player.moveLeft(dt);
            }

            if(key.w == "keyup" && this.bullets.length < this.player.maxShots) {
                this.bullets.push(new Bullet(new Position(this.player.position.x, this.player.position.y - this.player.size)))
            }

            for(let i = 0; i < this.invaderBullets.length; ++i) {
                let bullet = this.invaderBullets[i];

                if(bullet.position.y > this.player.position.y - this.player.size && Math.abs(bullet.position.x - this.player.position.x) < this.player.size) {
                    this.lives--;
                    this.invaderBullets.splice(i, 1);
                    --i;

                    if(this.lives == 0) {
                        this.running = false;
                        this.complete = false;
                    }
                }
            }
        }
    }

    draw(g) {
        this.stars.forEach(star => star.draw(g));
        this.player.draw(g);
        this.cluster.draw(g);
        this.bullets.forEach(bullet => bullet.draw(g));
        this.invaderBullets.forEach(bullet => bullet.draw(g));
        this.particles.forEach(particle => particle.draw(g));
        
        for(let i = 0; i < this.lives; ++i)
            Player.drawShip(g, {x: 90 + 50 * i, y: 30}, 20);

            g.text({x: 10, y: 30}, 20, "Lives: ", "rgb(244, 254, 57, 1)")
        g.text({x: canvas.width/2, y: 30}, 20, "Level: " + this.level + "   Score: " + this.score, "rgb(244, 254, 57, 1)", true)
        g.text({x: canvas.width - 100, y: 30}, 20, "High Score: " + this.heightScore, "rgb(244, 254, 57, 1)", true)
        
        
        if(!this.running) {
            if(this.complete) {
                g.text({x: canvas.width/2,y: canvas.height/2}, 50, "YOU WON THIS ROUND","rgb(244, 254, 57, 1)", true)
                g.text({x: canvas.width/2,y: canvas.height/2 + 30}, 20, "Press S to enter next round","rgb(244, 254, 57, 1)", true)
                if(key.s == "keydown") {
                    this.complete = false;
                    this.running = true;
                    this.wave_w += 2;
                    if(this.level % 2 == 0)
                        this.wave_h += 2;
                    this.cluster = new Cluster(new Position(0, 0), this.wave_w, this.wave_h)
                    this.level++;
                    this.bullets = [];
                    this.invaderBullets = [];
                    this.player.position.x = canvas.width/2;
                    this.particles = [];
                }
            }else {
                if(key.s == "keydown") {
                    this.complete = false;
                    this.running = true;
                    this.wave_w = 2;
                    this.wave_h = 2;
                    this.cluster = new Cluster(new Position(0, 0), this.wave_w, this.wave_h)
                    this.level = 1;
                    this.score = 0;
                    this.lives = 3;
                    this.bullets = [];
                    this.invaderBullets = [];
                    this.player.position.x = canvas.width/2
                    this.particles = [];

                }
                g.text({x: canvas.width/2,y: canvas.height/2}, 50, "GAME OVER","rgb(244, 254, 57, 1)", true)
                g.text({x: canvas.width/2,y: canvas.height/2 + 30}, 20, "Press S to restart game","rgb(244, 254, 57, 1)", true)
            }
        }
    }
    
}

class Particle {
    constructor(position, color) {
        this.position = position;
        this.color = color;
        this.v = Math.random() * 2 + .2;
        this.angle = -Math.random() * Math.PI;
        this.size = Invader.pixelSize;
    }

    tick(dt) {
        this.position.x += this.v * Math.cos(this.angle)
        this.position.y += this.v * Math.sin(this.angle)
        this.size -= .02;
        if(this.size < 0)
            GameHandler.object.particles.splice(this, 1);
    }

    draw(g) {
        g.rectangle(this.position, this.size, this.size, this.color);
    }
}

export default class Simulation {
    
    setup() {
        GameHandler.object = new GameHandler();
    }

    update(g, dt) {
        g.rectangle({x: 0, y: 0}, canvas.width, canvas.height, "rgb(0, 0, 0, 1)");
        GameHandler.object.tick(dt);
        GameHandler.object.draw(g);
    }

    end() {
        delete GameHandler.object;
    }
}