import { Position } from "../graphics";
import { canvas, running } from "../canvas.js";
import { key, mouse } from "../inputHandler";

class Box {
    static objects = [];
    static i = 0;
    static q = 0;
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    static remove() {
        Box.objects.splice(Box.q, 1);
        --Box.q;
    }

    static routes = {
        '1000': '0000',
        '0100': '0000',
        '0010': '0000',
        '0001': '0000',
        '1100': '0000',
        '1010': '0000',
        '1001': '0000',
        '0110': '0000',
        '0101': '0000',
        '0011': '0000',
        '1110': '0000',
        '1101': '0000',
        '1011': '0000',
        '0111': '0000',
        '1111': '0000',
        
        '1222': '2222',
        '2122': '2222',
        '2212': '2222',
        '2221': '2222',
        '1122': '2222',
        '1212': '2222',
        '1221': '2222',
        '2112': '2222',
        '2121': '2222',
        '2211': '2222',
        '1112': '2222',
        '1121': '2222',
        '1211': '2222',
        '2111': '2222',

        '1130': '1120',
        '1103': '1102',

        '1202': '2202',
        '2102': '2202',
        '1002': '0002',
        '0102': '0002',
        '1020': '0020',
        '0120': '0020',
        '1220': '2220',
        '2120': '2220',
        '0012': '0002',
        '0021': '0020',
        '1021': '0020',
        '0121': '0020',
        '1012': '0002',
        '0122': '1022',
        '0112': '0002',
        '2201': '2202',
        '2101': '2202',
        '1201': '2202',
        '1210': '2220',
        '2110': '2220',
        '2210': '2220',
        '2100': '2200',
        '1200': '2200',
        
    };

    static draw(g) {

        let last = "";
        for(Box.i = 0; Box.i < Box.objects.length - 1; ++Box.i) {
            for(Box.q = Box.i + 1; Box.q < Box.objects.length; ++Box.q) {
                
                let b1 = Box.objects[Box.i];
                let b2 = Box.objects[Box.q];

                let l = Math.sign(b1.x[0] - b2.x[0]) + Math.sign(b1.x[0] - b2.x[1]) + 2;
                let r = Math.sign(b2.x[0] - b1.x[1]) + Math.sign(b2.x[1] - b1.x[1]) + 2;
                let u = Math.sign(b1.y[0] - b2.y[0]) + Math.sign(b1.y[0] - b2.y[1]) + 2;
                let d = Math.sign(b2.y[0] - b1.y[1]) + Math.sign(b2.y[1] - b1.y[1]) + 2;

                if(l == 3)
                    l = 2;
                if(r == 3)
                    r = 2;

                let value = '' + l + r + u + d;
                if(Box.routes[value])
                    value = Box.routes[value];

                let area = (b1.x[1] - b1.x[0]) * (b1.y[1] - b1.y[0]);
                last = value;

                switch(value) {
                    case '0000':
                        Box.remove();
                    break;
                    case '2222':
                        b1.x[0] = b2.x[0];
                        b1.x[1] = b2.x[1];
                        b1.y[0] = b2.y[0];
                        b1.y[1] = b2.y[1];
                        Box.remove();
                    break;
                    case '2020':
                        Box.add(b2.x[0], b1.x[1], b1.y[0], b2.y[1]);
                        b2.y[1] = b1.y[0]
                        b1.y[0] = Box.last().y[1];
                    break;
                    case '2002':
                        Box.add(b2.x[0], b1.x[1], b2.y[0], b1.y[1]);
                        b2.y[0] = b1.y[1]
                        b1.y[1] = Box.last().y[0];
                    break;
                    case '0220':
                        Box.add(b1.x[0], b2.x[1], b1.y[0], b2.y[1]);
                        b2.y[1] = b1.y[0]
                        b1.y[0] = Box.last().y[1];
                    break;
                    case '0202':
                        Box.add(b1.x[0], b2.x[1], b2.y[0], b1.y[1]);
                        b2.y[0] = b1.y[1]
                        b1.y[1] = Box.last().y[0];
                    break;

                    // Right side
                    case '2000': // Green out of bound
                        Box.add(b1.x[0], b1.x[1], b2.y[1], b1.y[1]);
                        b2.x[1] = b1.x[1];
                        b1.y[1] = b2.y[0];
                    break;
                    case '2010': // Green out of bound
                        b2.x[1] = b1.x[1];
                        b1.y[0] = b2.y[1];
                    break;
                    case '2001': // Green out of bound
                        b2.x[1] = b1.x[1];
                        b1.y[1] = b2.y[0];
                    break;
                    case '2011': // Red out of bound
                        b1.x[0] = b2.x[0];
                        Box.remove();
                    break;
                    case '2022': // Red out of bound
                        Box.add(b2.x[0], b2.x[1], b1.y[1], b2.y[1]);
                        b1.x[0] = b2.x[0];
                        b2.y[1] = b1.y[0];
                    break;
                    case '2021': // Red out of bound
                        b1.x[0] = b2.x[0];
                        b2.y[1] = b1.y[0];
                    break;
                    case '2012': // Red out of bound
                        b1.x[0] = b2.x[0];
                        b2.y[0] = b1.y[1];
                    break;

                    // Left side
                    case '0200': // Green out of bound
                        Box.add(b1.x[0], b1.x[1], b2.y[1], b1.y[1]);
                        b2.x[0] = b1.x[0];
                        b1.y[1] = b2.y[0];
                    break;
                    case '0210': // Green out of bound
                        b2.x[0] = b1.x[0];
                        b1.y[0] = b2.y[1];
                    break;
                    case '0201': // Green out of bound
                        b2.x[0] = b1.x[0];
                        b1.y[1] = b2.y[0];
                    break;
                    case '0211': // Red out of bound
                        b1.x[1] = b2.x[1];
                        Box.remove();
                    break;
                    case '0222': // Red out of bound
                        Box.add(b2.x[0], b2.x[1], b1.y[1], b2.y[1]);
                        b1.x[1] = b2.x[1];
                        b2.y[1] = b1.y[0];
                    break;
                    case '0212': // Red out of bound
                        b1.x[1] = b2.x[1];
                        b2.y[0] = b1.y[1];
                    break;
                    case '0221': // Red out of bound
                        b1.x[1] = b2.x[1];
                        b2.y[1] = b1.y[0];
                    break;

                    case '1120': // Red out of bound
                        b1.y[0] = b2.y[0];
                        Box.remove();
                    break;
                    case '1102': // Red out of bound
                        b1.y[1] = b2.y[1];
                        Box.remove();
                    break;
                    case '2202':
                        b1.y[1] = b2.y[0];
                    break;
                    case '0002':
                        b2.y[0] = b1.y[1];
                    break;
                    case '0020':
                        b2.y[1] = b1.y[0];
                    break;
                    case '2220':
                        b1.y[0] = b2.y[1];
                    break;
                    case '0022':
                        Box.add(b2.x[0], b2.x[1], b1.y[1], b2.y[1]);
                        b2.y[1] = b1.y[0];
                    break;
                    case '0022':
                        Box.add(b2.x[0], b2.x[1], b1.y[1], b2.y[1]);
                        b2.y[1] = b1.y[0];
                    break;
                    case '1022':
                        Box.add(b2.x[0], b2.x[1], b1.y[1], b2.y[1]);
                        b2.y[1] = b1.y[0];
                    break;
                    case '2200':
                        Box.add(b1.x[0], b1.x[1], b2.y[1], b1.y[1]);
                        b1.y[1] = b2.y[0];
                    break;
                    default: continue;
                }
                
                if(area - (b1.x[1] - b1.x[0]) * (b1.y[1] - b1.y[0]) < 0)
                    Box.q = Box.i;
            }
            
        }

        g.ctx.beginPath();
        g.ctx.strokeStyle = "rgb(20, 140, 250, .5)";
        Box.objects.forEach(box => {
            
            //g.rectangle({x:box.x[0], y: box.y[0]}, box.x[1] - box.x[0], box.y[1] - box.y[0], "rgb(250, 0, 0, .5)");
            //g.border({x:box.x[0], y: box.y[0]}, box.x[1] - box.x[0], box.y[1] - box.y[0], "rgb(0, 0, 0, .5)");

            
            for(let y = box.y[0]; y < box.y[1]; y += 2) {
                let x = box.x[0];

                while(x < box.x[1]) {

                    while(!Particle.heat(x, y) && x < box.x[1])
                        x += Particle.size;
                    
                    if(x >= box.x[1])
                        break;

                    --x;
                    while(Particle.heat(x, y))
                        --x;
                    ++x;

                    g.ctx.moveTo(x, y);
                    
                    x += Particle.size;
                    while(Particle.heat(x, y) && x < box.x[1])
                        x += Particle.size;
                    
                    while(!Particle.heat(x - 1, y))
                        --x;

                    g.ctx.lineTo(x, y);
                }
            }
            
            
        });
        g.ctx.stroke();
        
    }

    static last() {
        return Box.objects[Box.objects.length - 1];
    }

    static add(x0, x1, y0, y1) {
        Box.objects.push(new Box([x0, x1], [y0, y1]));
    }
}

class Particle {
    static objects = [];
    static velocity_conserved_after_wall_collision = 1;
    static velocity_conserved_after_particle_collision = 1;

    static pullsize = 100;
    static size = 5;
    static size2 = 25;
    color = "rgb(0, 0, 0, 1)";
    vx = (Math.random() - .5) * 0;
    vy = (Math.random() - .5) * 0;
    constructor(position) {
        this.position = position;
        
        this.v = Math.sqrt(Math.pow(this.vx, 2) + Math.pow(this.vy, 2));

    }

    tick(dt) {

        this.v = Math.sqrt(Math.pow(this.vx, 2) + Math.pow(this.vy, 2));
        if(this.v == 0)
            this.theta = 0;
        else this.theta = Math.acos(this.vx / this.v) * Math.sign(this.vy);
        
        this.position.x += this.vx * dt;
        this.position.y += this.vy * dt;
        this.vy += 0.2;
        
        if(mouse.Left == "mousedown") {
            let distance = this.position.distance(mouse.position);
            if(distance < Particle.pullsize) {
                let angle = this.position.angle(mouse.position, distance);
    
                this.vx += Math.cos(angle) * .001 * dt * distance;
                this.vy += Math.sin(angle) * .001 * dt * distance;
            }
        }


        this.wallCollision();
    }

    wallCollision() {
        if(this.position.x > canvas.width - Particle.size && this.vx > 0) {
            this.position.x = canvas.width - Particle.size;
            this.vx *= -Particle.velocity_conserved_after_wall_collision * 0.9;
        }
        else if(this.position.x < Particle.size && this.vx < 0) {
            this.position.x = Particle.size;
            this.vx *=  -Particle.velocity_conserved_after_wall_collision * 0.9;
        }

        if(this.position.y > canvas.height - Particle.size && this.vy > 0) {
            this.position.y = canvas.height - Particle.size;
            this.vy *=  -Particle.velocity_conserved_after_wall_collision * 0.7;
        }
        else if(this.position.y < Particle.size && this.vy < 0) {
            this.position.y = Particle.size;
            this.vy *=  -Particle.velocity_conserved_after_wall_collision * 0.9;
        }
    }

    static tick(dt) {
        Particle.objects.forEach(particle => {
            particle.tick(dt);
        });

        Particle.collision();
    }

    static draw(g) {

        Box.objects = [];

        Particle.objects.forEach(particle => {


            let x = [Math.round(particle.position.x - Particle.size), Math.round(particle.position.x + Particle.size)];
            while(Particle.heat(x[0], particle.position.y)) --x[0];
            while(Particle.heat(x[1], particle.position.y)) ++x[1];

            let y = [Math.round(particle.position.y - Particle.size), Math.round(particle.position.y + Particle.size)];
            while(Particle.heat(particle.position.x, y[0])) --y[0];
            while(Particle.heat(particle.position.x, y[1])) ++y[1];

            
            
            while(Particle.heat(x[0] - 1, y[0] - 1)) {
                --x[0];
                --y[0];
            }
            
            while(Particle.heat(x[1] + 1, y[0] - 1)) {
                ++x[1];
                --y[0];
            }
            
            while(Particle.heat(x[0] - 1, y[1] + 1)) {
                --x[0];
                ++y[1];
            }

            while(Particle.heat(x[1] + 1, y[1] + 1)) {
                ++x[1];
                ++y[1];
            }
            

            Box.objects.push(new Box([x[0], x[1]], [y[0], y[1]]));
            
        });
    }

    static spawnRandom(n) {
        for(let i = 0; i < n; ++i)
            Particle.objects.push(new Particle(new Position(Math.random() * canvas.width, Math.random() * canvas.height)))
    }

    static heat(x, y) {
        let value = 0;

        Particle.objects.forEach(particle => {

            value += Particle.size2 / (Particle.size2 + Math.pow(particle.position.x - x, 2) + Math.pow(particle.position.y - y, 2));
        });

        return value > .5;
    }

    static collision() {

        for(let i = 0; i < Particle.objects.length - 1; ++i) {
            for(let q = i + 1; q < Particle.objects.length; ++q) {
                let p1 = Particle.objects[i];
                let p2 = Particle.objects[q];

                let distance = p1.position.distance(p2.position);

                if(distance < Particle.size * 2) {
                    let angle = p1.position.angle(p2.position, distance);
                    
                    let theta1 = p1.theta - angle;
                    let theta2 = p2.theta - angle;

                    let v1_cos = p1.v * Math.cos(theta1)
                    let v1_sin = p1.v * Math.sin(theta1)

                    let v2_sin = p2.v * Math.sin(theta2)
                    let v2_cos = p2.v * Math.cos(theta2)

                    let _cosa = Math.cos(angle);
                    let _sina = Math.sin(angle);
                    let _cos = Math.sin(angle + Math.PI / 2);
                    let _sin = Math.sin(angle + Math.PI / 2);

                    let x0 = (p2.position.x + p1.position.x) / 2;
                    let y0 = (p2.position.y + p1.position.y) / 2;
                    let xd = Particle.size * _cosa;
                    let yd = Particle.size * _sina;
                    
                    p1.position.x = x0 - xd;
                    p1.position.y = y0 - yd;

                    p2.position.x = x0 + xd;
                    p2.position.y = y0 + yd;


                    p1.vx = (v2_cos * _cosa + v1_sin * _cos) * Particle.velocity_conserved_after_particle_collision * 0.9;
                    p1.vy = (v2_cos * _sina + v1_sin * _sin) * Particle.velocity_conserved_after_particle_collision * 0.9;
        
                    p2.vx = (v1_cos * _cosa + v2_sin * _cos) * Particle.velocity_conserved_after_particle_collision * 0.9;
                    p2.vy = (v1_cos * _sina + v2_sin * _sin) * Particle.velocity_conserved_after_particle_collision * 0.9;
                }
            }
        }
        
    }
    
}

export default class Simulation {
    
    setup() {
        Particle.spawnRandom(50);
    }

    update(g, dt) {

        Particle.tick(dt);
        Particle.draw(g);
        Box.draw(g);
        g.ring(mouse.position, Particle.pullsize, 2, "rgb(0, 0, 0, .5)");

        let v = 0;
        for(let i = 0; i < Particle.objects.length; ++i)
            v += Particle.objects[i].v;

        g.text({x: 100, y: 100}, 20, v, "rgb(0, 0, 0, 1)");


    }

    end() {
        Particle.objects = [];
        Box.objects = [];
    }
}