import { Position } from "../graphics";
import { canvas, running } from "../canvas.js";
import { key, mouse } from "../inputHandler";

class Point {
    static objects = [];
    color = "rgb(250, 0, 0, 1)";
    
    acx = 0;
    acy = 0;
    fx = 0;
    fy = 0;
    constructor(position) {
        this.position = position;
    }

    tick() {

        if (this.position.y >= canvas.height) {
            this.fy = 0;
            this.position.y = canvas.height;
        }

        else if (this.position.y < 0) {
            this.position.y = 0;
            this.fy = 0;
        }

        if (this.position.x < 0) {
            this.fx = 0;
            this.position.x = 0;
        }

        else if (this.position.x > canvas.width) {
            this.position.x = canvas.width;
            this.fx = 0;
        }


        if(!this.nailed) {
            this.fx += this.acx * Cloth.h 
            this.fy += (this.acy + Cloth.objects[0].gravity) * Cloth.h 
        }

        this.position.x += this.fx * Cloth.h;
        this.position.y += this.fy * Cloth.h;
        this.acx = 0;
        this.acy = 0;

        if(this.nailed) {
            this.fx = 0;
            this.fy = 0;
        }
    }

    draw(g) {
        g.circle(this.position, 3, this.color)
    }

    checkmouse() {
        if(!mouse.locked && mouse.position.distance(this.position) < 10) {
            mouse.locked = this;
        }

        if(mouse.locked == this) {

            if(key.d == "keyup")
                this.nailed = this.nailed ? false : true;

            this.fx = mouse.position.x - this.position.x;
            this.fy = mouse.position.y - this.position.y;
        }
    }
}

class Spring {

    static colors = {PullSpring:"rgb(0, 250, 0, .5)", PushSpring: "rgb(0, 0, 250, .5)"};
    points = [];
    constructor(head, tail, w, h, cloth) {
        this.cloth = cloth;
        this.p1 = head;
        this.p2 = tail;
        this.w = w;
        this.h = h;
        this.l = Math.sqrt(Math.pow(this.w * this.cloth.l_x, 2) + Math.pow(this.h * this.cloth.l_y, 2));
    }
    
    tick() {

        this.l = Math.sqrt(Math.pow(this.w * this.cloth.l_x, 2) + Math.pow(this.h * this.cloth.l_y, 2));
        let distance = this.p1.position.distance(this.p2.position);
        if(this.active(distance)) {
            let angle = this.p2.position.angle(this.p1.position, distance);
            let ax = this.force() * Math.cos(angle) * (this.l - distance) + this.cloth.damping * (this.p2.fx - this.p1.fx);
            let ay = this.force() * Math.sin(angle) * (this.l - distance) + this.cloth.damping * (this.p2.fy - this.p1.fy);
            this.p1.acx += ax;
            this.p1.acy += ay;
            this.p2.acx -= ax;
            this.p2.acy -= ay;
        }
    }
}

class PullSpring extends Spring {
    static color = "rgb(0, 250, 0, .5)";
    constructor(head, tail, w, h, cloth) {
        super(head, tail, w, h, cloth);
    }

    active(distance) {
        return distance > this.l;
    }

    draw(g) {
        g.line(this.p1.position, this.p2.position, PullSpring.color, 1)
    }

    force() {
        return this.cloth.pullforce;
    }
}

class PushSpring extends Spring  {
    static color = "rgb(0, 0, 250, .5)";
    constructor(head, tail, w, h, cloth) {
        super(head, tail, w, h, cloth);
    }

    active(distance) {
        return distance < this.l;
    }

    draw(g) {
        g.line(this.p1.position, this.p2.position, PushSpring.color, 1)
    }
    force() {
        return this.cloth.pushforce;
    }
}

class Cloth {
    gravity = .2;
    damping = 1;
    pullforce = .2;
    pushforce = .2;
    static h = .125;
    static objects = [];
    static color = "rgb(0, 0, 0, .1)";
    static push_matrix = [
        [-2, -2],  [0, -2],  [2, -2],                        
        [-2,  0],            [2,  0],  
        [-2,  2],  [0,  2],  [2,  2],
    ];
    static pull_matrix = [
        [-1, -1], [0, -1], [1, -1],
        [-1,  0],
    ];

    points = [[]];
    springs = [];

    w = 1;
    h = 1;
    constructor(position, w, h, l_x, l_y) {
        this.position = position;
        this.l_x = l_x;
        this.l_y = l_y;
        
        this.points[0][0] = new Point(new Position(this.position.x, this.position.y));

        while(this.h < h)
            this.add_row_after();

        while(this.w < w)
            this.add_column_after();

        for(let i = 0; i < this.w; ++i) {
            this.points[i][0].nailed = true;
        }

        console.log(this.springs)
    }

    add_column_after() {
        this.points.push(new Array(this.h));

        for(let i = 0; i < this.h; ++i) {
            let host = this.points[this.w - 1][i];
            let angle = this.w > 1 ? this.points[this.w - 2][i].position.angle(host.position) : 0;
            this.add_point(new Position(host.position.x + this.l_x * Math.cos(angle), host.position.y + this.l_x * Math.sin(angle)), this.w, i);
        }

        ++this.w;
    }

    add_column_before() {
        this.points.splice(0, 0, new Array(this.h));

        for(let i = 0; i < this.h; ++i) {
            let host = this.points[1][i];
            let angle = this.w > 2 ? this.points[2][i].position.angle(host.position) : -Math.PI;
            this.add_point(new Position(host.position.x + this.l_x * Math.cos(angle), host.position.y+ this.l_x * Math.sin(angle)), 0, i);
        }

        ++this.w;
    }

    add_row_after() {

        for(let i = 0; i < this.w; ++i) {
            let host = this.points[i][this.h - 1];
            let angle = this.h > 1 ? this.points[i][this.h - 2].position.angle(host.position) : Math.PI/2;
            this.add_point(new Position(host.position.x + this.l_y * Math.cos(angle), host.position.y + this.l_y * Math.sin(angle)), i, this.h)
        }

        ++this.h;
    }

    add_row_before() {
        for(let i = 0; i < this.w; ++i) {
            this.points[i].splice(0, 0, undefined);
        }

        for(let i = 0; i < this.w; ++i) {
            let host = this.points[i][1];
            let angle = this.h > 2 ? this.points[i][2].position.angle(host.position) : -Math.PI/2;
            this.add_point(new Position(host.position.x + this.l_y * Math.cos(angle), host.position.y + this.l_y * Math.sin(angle)), i, 0)
        }

        ++this.h;
    }

    remove_row_after() {
        for(let i = 0; i < this.points.length; ++i) {
            this.remove_point(i, this.h - 1);
            this.points[i].splice(this.h - 1, 1);
        }
        
        --this.h;
    }

    remove_row_before() {
        for(let i = 0; i < this.points.length; ++i) {
            this.remove_point(i, 0);
            this.points[i].splice(0, 1);
        }

        --this.h;
    }

    remove_column() {
        for(let i = 0; i < this.h; ++i)
            this.remove_point(this.w - 1, i);
        
        this.points.splice(this.w - 1, 1);

        --this.w;
    }

    add_point(position, w, h) {
        this.points[w][h] = new Point(position);
        if(this.points[w - 1]&& this.points[w - 1][h].nailed)
            this.points[w][h].nailed = true;

        Cloth.pull_matrix.forEach((m, i) => {
            let x = w + m[0];
            let y = h + m[1];
            if(this.points[x] && this.points[x][y])
                this.springs.push(new PullSpring(this.points[x][y], this.points[w][h], m[0], m[1], this));
            
            x = w - m[0];
            y = h - m[1];
            if(this.points[x] && this.points[x][y])
                this.springs.push(new PullSpring(this.points[w][h], this.points[x][y], m[0], m[1], this));
        });
        
        Cloth.push_matrix.forEach((m, i) => {
            let x = w + m[0];
            let y = h + m[1];
            if(this.points[x] && this.points[x][y])
                this.springs.push(new PushSpring(this.points[x][y], this.points[w][h], m[0], m[1], this));
                
        });
    }

    remove_point(x, y) {
        for(let i = 0; i < this.springs.length; ++i)
            if(this.springs[i].p1 == this.points[x][y] || this.springs[i].p2 == this.points[x][y]) {
                this.springs.splice(i, 1);
                --i;
            }

        this.points[x][y] = undefined;
    }

    tick() {


        while(this.w > this.w0)
            this.remove_column();

        while(this.w < this.w0)
            this.add_column_after();

        while(this.h > this.h0)
            this.remove_row_after();

        while(this.h < this.h0)
            this.add_row_after();

        this.springs.forEach(spring => spring.tick());

        this.points.forEach(column => {
            column.forEach(point => point.tick());
        });

        if (mouse.Right == "mousedown") {
            if (mouse.position.x != mouse.previousPosition.x || mouse.position.y != mouse.previousPosition.y) {

                for (let i = 0; i < this.springs.length; ++i) {
                    let p1 = this.springs[i].p1;
                    let p2 = this.springs[i].p2;

                    let X1 = p1.position.x;
                    let X2 = p2.position.x;
                    let X3 = mouse.position.x;
                    let X4 = mouse.previousPosition.x;
                    let Y1 = p1.position.y;
                    let Y2 = p2.position.y;
                    let Y3 = mouse.position.y;
                    let Y4 = mouse.previousPosition.y;
                    
                    if (X3 == X4)
                        break;

                    // y = k*x + m
                    let A1 = (Y1 - Y2) / (X1 - X2);
                    let A2 = (Y3 - Y4) / (X3 - X4);
                    let b1 = Y1 - X1 * A1;
                    let b2 = Y3 - X3 * A2;

                    if (A1 == A2)
                        continue;

                    let Xa = (b2 - b1) / (A1 - A2);

                    if (Xa < Math.max(Math.min(X1, X2), Math.min(X3, X4)) ||
                        Xa > Math.min(Math.max(X1, X2), Math.max(X3, X4)))
                        continue;
                    else {
                        if(i != 2 && this.springs[i].constructor.name == "PullSpring")
                            p1.broken = true;
                            
                        this.springs.splice(i, 1);
                    }
                }
            }
        }

    }

    draw(g) {

            
        this.springs.forEach((spring, i) => {
            spring.draw(g);
        });
        

        
        /*
        g.ctx.fillStyle = "rgb(0, 0, 0, .5)"
        for(let i = 0; i < this.w - 1; ++i) {
            for(let q = 0; q < this.h - 1; ++q) {

                if(this.points[i][q].broken)
                    continue;

                g.ctx.beginPath();
                g.ctx.moveTo(this.points[i][q].position.x, this.points[i][q].position.y);
                g.ctx.lineTo(this.points[i + 1][q].position.x, this.points[i + 1][q].position.y);
                g.ctx.lineTo(this.points[i + 1][q + 1].position.x, this.points[i + 1][q + 1].position.y);
                g.ctx.lineTo(this.points[i][q + 1].position.x, this.points[i][q + 1].position.y);
                g.ctx.fill();
            }
        }*/
    }
}

class GUI {
    static gui;
    sliders = [];
    constructor() {
        this.sliders.push(new Slider(new Position(canvas.width - 250, 50), "width", "l_x", 8, [2, 20]));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 120), "height", "l_y", 8, [2, 20]));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 190), "columns", "w0", 15, [1, 50]));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 260), "rows", "h0", 20, [1, 50]));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 330), "gravity", "gravity", .2, [0, 1], true));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 400), "damping", "damping", .5, [0, 1], true));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 470), "pull force", "pullforce", .5, [0, 1], true));
        this.sliders.push(new Slider(new Position(canvas.width - 250, 540), "push force", "pushforce", .5, [0, 1], true));
    }

    tick() {
        this.sliders.forEach(slider => slider.tick());
    }

    draw(g) {
        this.sliders.forEach(slider => slider.draw(g));
    }
}

class Slider {

    static color = "rgb(0, 0, 0, .5)";
    width = 100;
    height = 35;
    constructor(position, title, prop, value, range, float) {
        this.position = position;
        this.prop = prop;
        this.title = title;
        this.range = range
        if(float)
            this.float = float;

        this.value =  (value - range[0]) / (range[1] - range[0]);
        this.setValue();
    }

    tick() {

        if(mouse.Left == "mousedown") {
            if(!mouse.locked && this.position.add(this.width * this.value, this.height/2).distance(mouse.position) < this.height - 5) {
                mouse.locked = this;
            }

            if(mouse.locked == this) {
                this.value = (mouse.position.x - this.position.x)/this.width;
                if(this.value < 0) this.value = 0;
                else if(this.value > 1) this.value = 1;

                this.setValue();

            }
        }
    }

    setValue() {
        
        this.scaledvalue = this.float ? this.range[0] + this.value * (this.range[1] - this.range[0]) : Math.round(this.range[0] + this.value * (this.range[1] - this.range[0]));
        Cloth.objects[0][this.prop] = this.scaledvalue
    }

    draw(g) {
        g.bar(this.position, this.width, this.height, Slider.color)
        g.bar(this.position.add(0, 5), this.width * this.value, this.height - 10, Slider.color)
        g.circle(this.position.add(this.width * this.value, this.height/2), this.height/2 - 5, "rgb(250, 250, 250, .8)")

        g.ctx.beginPath();
        g.ctx.arc(this.position.x, this.position.y + this.height/2, this.height/2 - 5, Math.PI/2, 3 * Math.PI/2);
        g.ctx.arc(this.position.x + this.width * this.value, this.position.y + this.height/2, this.height/2 - 5,  3 * Math.PI/2, Math.PI/2, true)
        g.ctx.arc(this.position.x + this.width * this.value, this.position.y + this.height/2, this.height/2 - 5,  Math.PI/2, 3 * Math.PI/2, true)
        g.ctx.arc(this.position.x + this.width, this.position.y + this.height/2, this.height/2 - 5,  3 * Math.PI/2, Math.PI/2)
        g.ctx.save();
        g.ctx.clip();
        g.text(this.position.add(this.width/2, this.height/2 + 4), 15, this.title, "rgb(250, 250, 250, 1)", true);
        g.ctx.restore();

        g.ctx.beginPath();
        g.ctx.arc(this.position.x + this.width * this.value, this.position.y + this.height/2, this.height/2 - 5, 0, Math.PI * 2)
        g.ctx.save();
        g.ctx.clip();
        g.text(this.position.add(this.width/2, this.height/2 + 4), 15, this.title, "rgb(0, 0, 0, 1)", true);
        g.ctx.restore();


        g.ctx.beginPath();
        g.ctx.arc(this.position.x + this.width * .3, this.position.y, this.height/2, Math.PI, 3 * Math.PI/2)
        g.ctx.arc(this.position.x + this.width * .7, this.position.y, this.height/2, 3 * Math.PI/2, 0)
        g.ctx.fillStyle = "rgb(0, 0, 0, .5)";
        g.ctx.fill();

        g.text(this.position.add(this.width/2, 0), 15, Math.floor(this.scaledvalue * 100)/100, "rgb(250, 250, 250, 1)", true);
        

    }
}

export default class Simulation {
    
    setup() {
        Cloth.objects.push(new Cloth(new Position(canvas.width/2 - 300, 100), 1, 1, 5, 5))
        GUI.gui = new GUI();
    }

    update(g, dt) {

        GUI.gui.tick();
        GUI.gui.draw(g);

        if(mouse.Left == "mousedown") {
        
            Cloth.objects[0].points.forEach(column => {
    
                column.forEach(point => point?.checkmouse());
            });
        }

        for(let i = 0; i < 1; i += Cloth.h)
            Cloth.objects[0].tick();

        Cloth.objects[0].draw(g);
    }

    end() {
        Cloth.objects = [];
        GUI.gui = undefined;
    }
}