import {Position} from './graphics.js';
import {canvas} from './canvas.js';

export let key = {}

export let mouse = {position: new Position(0, 0), previousPosition: new Position(0, 0)};


export function resetKeys() {
	for (let element in key) {
		if (key[element] == "keyup") { key[element] = undefined; }
	}

	if (mouse.Right == "mouseup") mouse.Right = undefined;
	if (mouse.Left == "mouseup")  {mouse.Left = undefined; mouse.locked = false;}
}

export function startEventListeners() {

	onkeydown = onkeyup = function (e) { key[e.key] = e.type; }
	onmousedown = onmouseup = function (e) {
		if (e.button == 0) mouse.Left = e.type;
		else if (e.button == 2) mouse.Right = e.type;
	}
	onmousemove = function(e) {
		mouse.position = new Position(e.clientX - canvas.getBoundingClientRect().left, e.clientY - canvas.getBoundingClientRect().top);
	}
}


export function stopEventListeners() {
	onkeydown = onkeyup = undefined;
	onmousedown = onmouseup = undefined;
	onmousemove = undefined;
}