import GUI from './gui.js';
import {Egg} from './fish.js';
import Fish from './fish.js';
import Food from './food.js';
import Particle from './particle.js';
import Seaurchin from './seaurchin.js';
import {canvas} from '../canvas.js';


let gui;
export default class Simulation {
    
    setup() {
        Food.spawnRandom(15);
        Seaurchin.spawnRandom(40);
        gui = new GUI();
    }

    update(g) {

        g.rectangle({x: 0, y: 0}, canvas.width, canvas.height, "lightblue");
        
        gui.tick();
        if(gui.clock.value > 0) {
            Fish.tick();
            Particle.objects.forEach(particle => particle.tick());
        }

        Fish.clear();
        Food.clear();
        Particle.clear();
        
        Egg.draw(g);
        Seaurchin.objects.forEach(seaurchin => seaurchin.draw(g));
        Fish.draw(g);
        Food.objects.forEach(food => food.draw(g));
        Particle.objects.forEach(particle => particle.draw(g));
        gui.draw(g);
    }

    end() {
        Fish.objects = [];
        Food.objects = [];
        Seaurchin.objects = [];
        Egg.objects = [];
        Particle.objects = [];
        gui = undefined;
    }
}


export function newDay() {

    let data = {};
    Fish.properties.forEach(prop => {
        data[prop[0]] = {value: 0, range: [prop[1], prop[2]]};
    })

    for(let prop in data) {
        Fish.objects.forEach(fish =>{
            data[prop].value += fish[prop];
        });

        data[prop].value /= Fish.objects.length;
    }

    for(let prop in data) {
        let range = data[prop].range;
        data[prop] = data[prop].value - range[0];
        data[prop] /= range[1] - range[0];
    }

    console.log(data)

    
    Fish.objects.forEach(fish => {
        if(fish.energy == 0) 
            fish.alive = false;
        else fish.energy = 0;
    });

    Egg.hatch();
    Food.spawnRandom(10);
}