import React from 'react';
import './App.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Routes,
} from "react-router-dom";

import {startSimulation, endSimulation} from './simulations/canvas.js';

let projects = [["fish", 'fisk'], ["cloth", 'cloth'],["water", 'water'],["invaders", 'invaders'], ["unit", "unit"]];


class Project extends React.Component {

  reset(e) {
    e.target.src = e.target.src + "?a=" + Math.random();
  }
  
  render() {
    return (
      <section className='project'>
      <img className="gif" src={this.props.data[1] + ".gif"} onMouseOut={this.reset}/>
        <img src={this.props.data[1] + ".jpg"}/>
        <div className='nameTag'> 
          <p>&lt;{this.props.data[0]}/&gt;</p>
        </div>
      </section>
    );
  }
}

class ProjectList extends React.Component {


  render() {
    return (
      <section id='projectList'>
        {
          projects.map((project, i) => {
            return <Link to={project[0]} key={'link-' + project[0]}><Project data={project} key={project[0]}></Project></Link>}

          )
        }
      </section>
    );
  }
}



class AboutMe extends React.Component {
  render() {
    return <div><h1>about me</h1></div>
  }
}

class Contact extends React.Component {
  render() {
    return <div><h1>contact</h1></div>
  }
}

class CV extends React.Component {
  render() {
    return <div><embed id="cv" src="CV.pdf" width="800px" height="500px" /></div>
  }
}

class Navbar extends React.Component {

  pages = [['', 'projects'], ['aboutme', 'about me'], ['cv', 'CV'], ['contact', 'contact'],];

  constructor() {
    super();
    this.state = {
      selected: this.pages[0][0],
    }
  }
  

  render() {

    return(
        <nav>
          {
            this.pages.map(page => {

              return (
                <Link to={'/' + page[0]} className='link' key={page[0]}>
                  <button className='button' onClick={() => {this.setState({selected: page[0]}); endSimulation();}}>{page[1]}
                  {this.state.selected == page[0] &&
                  <div id='bar'></div>
                  }</button>
                  
                  </Link>
              )

            })
          }
        </nav>
    )
  }
}

class Simulation extends React.Component {

  constructor(props) {
    super();
    this.props = props;
  }

  componentDidMount() {

    startSimulation(this.props);
  }
  
  render () {
    return(
      <canvas id='canvas'></canvas>
    );
  }
}


function App() {
  return (
    <div className='App'>
      <Router>
        <header id="header">
          <img src='logo.svg'/>
          <Navbar/>
        </header>
        <Routes>
            {
              projects.map(project => {
                return <Route path={project[0]} key={"route-" + project[0]} element={<Simulation simulation={project[0]}></Simulation>}/>
              })
            }
            <Route path="/aboutme" element={<AboutMe/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/cv" element={<CV/>}/>
            <Route path="/" element={<ProjectList/>}/>
        </Routes>
      </Router>
      <footer id='footer'>
        <p>COPYRIGHT JOHN TEMBER</p>
      </footer>
    </div>
  );
}



export default App;
