
import {Position, Graphics} from './graphics.js';
import {resetKeys, key, mouse, startEventListeners, stopEventListeners} from './inputHandler.js';
import FishSimulation from './fish/update.js';
import WaterSimulation from './water/update.js';
import ClothSimulation from './cloth/update.js';
import InvadersSimulation from './invaders/update.js';
import UnitSimulation from './unit/update.js';
export let canvas;
let g;
export let running;
let currentSimulation;
let simulations = {fish: new FishSimulation(), water: new WaterSimulation(), cloth: new ClothSimulation(), invaders: new InvadersSimulation(), unit: new UnitSimulation()};

document.addEventListener('contextmenu', event => event.preventDefault());

export function startSimulation(prop) {
	canvas = document.getElementById('canvas');

	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight - document.getElementById('header').offsetHeight - document.getElementById('footer').offsetHeight
	g = new Graphics();
	running = true;

	currentSimulation = prop.simulation;
	simulations[currentSimulation].setup(g);
	startEventListeners();
	requestAnimationFrame(animate);
}

export function endSimulation() {

	if(currentSimulation) {
		g = undefined;
		canvas = undefined
		simulations[currentSimulation].end();
		currentSimulation = undefined;
		running = false;
		stopEventListeners();
	}
}

let prevTime = 0;
let dt = 0;
function animate(currentTime) {

	dt = currentTime - prevTime;
	prevTime = currentTime;
	
	if (!running)
		return;

	if (key.Escape == "keydown")
		running = false;

	g.rectangle({x: 0, y: 0}, canvas.width, canvas.height, "white");

	simulations[currentSimulation].update(g, 60 * dt/1000);

	resetKeys();
	mouse.previousPosition = new Position(mouse.position.x, mouse.position.y);

	requestAnimationFrame(animate);
}