
import { canvas } from '../canvas.js';
import {Position} from '../graphics.js'
import {mouse} from '../inputHandler.js'

let images = [];
let filter = [[2, 4, 5, 4, 2], [4, 9, 12, 9, 4], [5, 12, 15, 12, 5], [4, 9, 12, 9, 4], [2, 4, 5, 4, 2]];

class Image {

    list = [];
    greyscale = [];
    gradient = [];
    orientation = [];

    constructor(position, data, width, height) {
        this.position = position;
        this.width = width;
        this.height = height;
        this.i = images.length;

        for(let i = 0; i < this.width; ++i) {
            this.greyscale.push(new Array(this.height));
            this.gradient.push(new Array(this.height));
            this.orientation.push(new Array(this.height));
        }

        for(let i = 0; i < data.length; i += 4) {
            this.greyscale[i/4 % this.width][Math.floor(i/(4 * this.width))] = (0.2126 * data[i] + 0.7152 * data[i + 1] + 0.0722 * data[i + 2]);
        }


        for(let y = 2; y < this.height - 2; ++y) {
            for(let x = 2; x < this.width - 2; ++x) {

                let value = 0;
                for(let yf = 0; yf < 5; ++yf) {
                    for(let xf = 0; xf < 5; ++xf) {
                        value += filter[xf][yf] * this.greyscale[x + xf - 2][y + yf - 2];
                    }
                }

                this.greyscale[x][y] = value/159;
            }
        }

        for(let y = 1; y < this.height - 1; ++y) {
            for(let x = 1; x < this.width - 1; ++x) {

                let Gx = this.greyscale[x - 1][y - 1] * 3 + this.greyscale[x - 1][y] * 10 + this.greyscale[x - 1][y + 1] * 3;
                Gx -= this.greyscale[x + 1][y - 1] * 3 + this.greyscale[x + 1][y] * 10 + this.greyscale[x + 1][y + 1] * 3;
                
                let Gy = this.greyscale[x - 1][y - 1] * 3 + this.greyscale[x][y - 1] * 10 + this.greyscale[x + 1][y - 1] * 3;
                Gy -= this.greyscale[x - 1][y + 1] * 3 + this.greyscale[x][y + 1] * 10 + this.greyscale[x + 1][y + 1] * 3;
                
                this.gradient[x][y] = Math.sqrt(Math.pow(Gx, 2) + Math.pow(Gy, 2));
                this.orientation[x][y] = Math.atan2(Gy, Gx);
                
            }
        }
        
        for(let y = 1; y < this.height - 1; ++y) {
            for(let x = 1; x < this.width - 1; ++x) {

                let X = Math.round(Math.cos(this.orientation[x][y]));
                let Y = Math.round(Math.sin(this.orientation[x][y]));

                if(this.gradient[x + X][y + Y] >= this.gradient[x][y] || this.gradient[x - X][y - Y] >= this.gradient[x][y])
                    this.gradient[x][y] = 0;
                
            }
        }

        for(let y = 1; y < this.height - 1; ++y) {
            for(let x = 1; x < this.width - 1; ++x) {

                if(this.gradient[x][y] < .3 * 255)
                    this.gradient[x][y] = 0;
                
            }
        }
    }

     
    draw(g) {
        for(let y = 0; y < this.height; ++y) {
            for(let x = 0; x < this.width; ++x) {
                g.rectangle(this.position.add(x, y), 1, 1, g.rgb(this.gradient[x][y], this.gradient[x][y], this.gradient[x][y]));
            }
        }

        for(let i = 0; i < this.list.length; ++i) {
            g.image(this.position.add(25, this.height + 5 + (this.height - 50) * i), this.list[i][0], this.width - 50, this.height - 50);
        }
    }

    compare() {

        this.list = [];
        images.forEach(img => {
            if(img.i != this.i) {
            
                let error = 0;
                for(let y = 0; y < this.height; ++y) {
                    for(let x = 0; x < this.width; ++x) {
                        error += Math.abs(this.gradient[x][y] - img.gradient[x][y])
                    }
                }
                this.list.push([img.i, error]);
            }
        });

        this.list.sort((a, b) => a[1] - b[1] );
    }
}

export default class Simulation {
    
    setup(g) {
        let width = 100, height = 100;
        for(let i = 0; i < 11; ++i) {
            g.image({x: 0, y: 0}, i, width, height);
            images.push(new Image(new Position(20 + i * 120, 20), g.ctx.getImageData(0, 0, width, height).data, 100, 100));
        }
        images.forEach(img => img.compare());
    }

    update(g, dt) {
        images.forEach(img => img.draw(g));
    }

    end() {
    }
}