import {Position, Angle} from '../graphics.js';

export default class Skeleton {
    static objects = [];
    points = [];
    joints = [];

    color = "rgb(250, 0, 0, 1)";
    constructor(fish) {
        this.fish = fish;
        Skeleton.data.joints.forEach(joint => this.joints.push(new Joint(this, this.joints[joint.host], joint.length, joint.angle, joint.max_bend, joint.sway)));
        Skeleton.data.points.forEach(point => this.points.push(new Point(this, this.joints[point.host], point.dx, point.dy, point.dz)));
    }

    tick() {
        this.joints.forEach(joint => joint.tick());
        this.points.forEach(joint => joint.tick());
    }

    draw(g) {
        this.joints.forEach(joint => joint.draw(g));
        this.points.forEach(joint => joint.draw(g));
    }
}

class Joint {
    t = 0;
    static color = "rgb(200, 0, 80, .4)";
    constructor(skeleton, head = skeleton.fish, length, initialAngle, max_bend = 0, sway = 0) {

        this.skeleton = skeleton;
        this.head = head;
        this.length = {value: length * skeleton.fish.size, d: length};
        this.initialAngle = initialAngle;
        this.angle = new Angle(head.angle.value + initialAngle + Math.PI);
        this.max_bend = max_bend;
        this.sway = sway;
        this.position = head.position.circulation(this.length.value, this.angle.value + Math.PI);
    }

    tick() {

        this.length.value = this.skeleton.fish.size * this.length.d;
        this.angle.set(this.position.angle(this.head.position) + Math.sin(this.skeleton.fish.sway.t) * this.skeleton.fish.sway.length * this.sway);

        let midpoint_angle = this.head.position.signed_angle(this.head.position.circulation(1, this.initialAngle + this.head.angle.value));

        let overspilled_angle = this.angle_between(midpoint_angle, this.head.position.signed_angle(this.position));

        if(Math.abs(overspilled_angle) > this.max_bend)
            this.angle.set(midpoint_angle + Math.PI - this.max_bend * Math.sign(overspilled_angle));

        this.position = this.head.position.circulation(this.length.value, this.angle.value + Math.PI)
    }

    draw(g) {
        g.circle(this.position, 4, Joint.color);
        g.line(this.position, this.head.position, Joint.color);
    }

    angle_between(a1, a2) {
        let a = a1 - a2;
    
        if(a > Math.PI)
            a -= Math.PI * 2;
        if(a < -Math.PI)
            a += Math.PI * 2;
    
        return a;
    }
}

class Point {

    static color = "rgb(200, 0, 80, .4)";

    constructor(skeleton, host = skeleton.fish, dx, dy, dz = 0) {
        this.host = host;
        this.skeleton = skeleton;

        this.d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        let a = Math.acos(dx/this.d);
        if(dy < 0)
            a *= -1;
        if(!a)
            a = 0;
        
        a += host.angle.value

        this.position = new Position(host.position.x + skeleton.fish.size * this.d * Math.cos(a), host.position.y + skeleton.fish.size * this.d * Math.sin(a), skeleton.fish.size * dz);
        this.length = this.d * skeleton.fish.size;
        this.angle = this.position.angle(host.position) - this.host.angle.value - Math.PI;
        if(!this.angle)
            this.angle = 0;
    }

    tick() {
        this.length = this.d * this.skeleton.fish.size;
        this.position.x = this.host.position.x + this.length * Math.cos(this.angle + this.host.angle.value);
        this.position.y = this.host.position.y + this.length * Math.sin(this.angle + this.host.angle.value);
    }

    draw(g) {
        g.circle(this.position, 2, Point.color);
        //text(this.position, 10, this.skeleton.points.indexOf(this), {rgb: "black"})
    }
}

Skeleton.data = {
    joints: [
        {
            length: .4,
            angle: Math.PI,
            max_bend: .2,
        },
        {
            length: .15,
            angle: Math.PI,
            host: 0,
            max_bend: .4,
            sway: .3,
        },
        {
            length: .15,
            angle: Math.PI,
            host: 1,
            max_bend: .4,
        },
        {
            length: .15,
            angle: Math.PI,
            host: 2,
            max_bend: .4,
        },
        {
            length: .15,
            angle: Math.PI,
            host: 3,
            max_bend: .4,
        },
        {
            length: .5,
            angle: Math.PI/2,
            max_bend: 0,
        },
        {
            length: .5,
            angle: -Math.PI/2,
            max_bend: 0,
        },
        {
            length: .4,
            angle: -Math.PI/2 - .4,
            host: 5,
            max_bend: .6,
        },
        {
            length: .4,
            angle: Math.PI/2 + .4,
            host: 6,
            max_bend: .6,
        },
        {
            length: .6,
            angle: Math.PI + .35,
            host: 4,
            max_bend: .3,
        },
        {
            length: .6,
            angle: Math.PI - .35,
            host: 4,
            max_bend: .3,
        },
        {
            length: .7,
            angle: Math.PI + .7,
            host: 4,
            max_bend: .2,
        },
        {
            length: .7,
            angle: Math.PI - .7,
            host: 4,
            max_bend: .2,
        },
        {
            length: .7,
            angle: Math.PI + 1.1,
            host: 4,
            max_bend: .4,
        },
        {
            length: .7,
            angle: Math.PI - 1.1,
            host: 4,
            max_bend: .4,
        },
        {
            length: .3,
            angle: Math.PI - .7,
            host: 11,
            max_bend: .5,
        },
        {
            length: .3,
            angle: Math.PI + .7,
            host: 12,
            max_bend: .5,
        },
    ],
    points: [
        {
            dx: 0,
            dy: 0,
            dz: .6,
        },
        {
            dx: 0,
            dy: 0,
            dz: 1,
            host: 0,
        },
        {
            dx: 0,
            dy: 0,
            dz: .8,
            host: 1,
        },
        {
            dx: 0,
            dy: 0,
            dz: .4,
            host: 2,
        },
        {
            dx: 0,
            dy: 0,
            dz: .2,
            host: 3,
        },
        {
            dx: 0,
            dy: 0,
            host: 4,
        },
        {
            dx: 0,
            dy: 0,
            host: 5,
        },
        {
            dx: 0,
            dy: 0,
            host: 6,
        },
        {
            dx: 0,
            dy: 0,
            dz: .3,
            host: 7,
        },
        {
            dx: 0,
            dy: 0,
            dz: .3,
            host: 8,
        },
        {
            dx: 0,
            dy: -.3,
            host: 0,
        },
        {
            dx: 0,
            dy: .3,
            host: 0,
        },
        {
            dx: 0,
            dy: -.2,
            host: 1,
        },
        {
            dx: 0,
            dy: .2,
            host: 1,
        },
        {
            dx: 0,
            dy: -.15,
            host: 2,
        },
        {
            dx: 0,
            dy: .15,
            host: 2,
        },
        {
            dx: 0,
            dy: -.1,
            host: 3,
        },
        {
            dx: 0,
            dy: .1,
            host: 3,
        },
        {
            dx: .7,
            dy: -.2,
        },
        {
            dx: .7,
            dy: .2,
        },
        {
            dx: .3,
            dy: -.5,
        },
        {
            dx: .3,
            dy: .5,
        },
        {
            dx: -.53,
            dy: -.8,
        },
        {
            dx: -.53,
            dy: .8,
        },
        {
            dx: 0,
            dy: 0,
            dz: -.2,
            host: 9,
        },
        {
            dx: 0,
            dy: 0,
            dz: -.2,
            host: 10,
        },
        {
            dx: 0,
            dy: 0,
            dz: .1,
            host: 11,
        },,
        {
            dx: 0,
            dy: 0,
            dz: .1,
            host: 12,
        },
        {
            dx: 0,
            dy: 0,
            dz: .3,
            host: 13,
        },
        {
            dx: 0,
            dy: 0,
            dz: .3,
            host: 14,
        },
        {
            dx: 0,
            dy: 0,
            dz: .1,
            host: 15,
        },
        {
            dx: 0,
            dy: 0,
            dz: .1,
            host: 16,
        }
    ],
};