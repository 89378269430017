import {Angle, Position} from '../graphics.js';
import Skeleton from './skeleton.js';
import Food from './food.js';
import Brain from './brain.js';
import Particle from './particle';
import Seaurchin from './seaurchin.js';
import {canvas} from '../canvas.js';

export default class Fish {

    static properties = [["size", 15, 40], ["maxSpeed", 1, 4], ["vision", 0, 200], ["smart", 4, 30], ["r", 50, 200], ["g", 50, 200], ["b", 50, 200], ["turn_speed", .05, .4]]
    static objects = [];

    colors = [{r: 150, g: 150, b: 150}, {r: 100, g: 100, b: 100}];
    angle = new Angle(0);
    velocity = 0;
    sway = {t: 0, length: 1};
    eatTimer = {value: 0, threshold: 60};
    foodCount = 0;
    targetAngle = {value: new Angle(Math.PI * 3/2), time: 0, threshold: 3 * 60};
    energy = 100;

    constructor(position, props) {
        this.position = position;
        for(let prop in props)
            this[prop] = props[prop];

        this.skeleton = new Skeleton(this);
        this.brain = new Brain(this, 20);
        this.eyes = [new Eye(this, 1, .4, -.3), new Eye(this, 2, .4, .3)]
    }

    tick() {

        if(this.energy == 0)
            return
            
        ++this.targetAngle.time;
        if(this.targetAngle.time > this.targetAngle.threshold) {
            this.targetAngle.value = new Angle(Math.random() * Math.PI * 2);
            this.targetAngle.time = 0;
        }


        this.brain.tick();
        this.wall_collision();
        this.fish_collision();
        this.angle.normalize();
        this.calculate_targetAngle();


        this.sway.t += this.velocity * .1;
        this.calculateEnergy();
        this.eating();
        this.speedUp();
        
        Seaurchin.objects.forEach(seaurchin => {

            let distance = this.position.distance(seaurchin.position);
            if(distance < this.size + seaurchin.size) {
    
                if(this.slowed == undefined) {
                    this.energy -= 20;
                    this.slowed = {time: 0, duration: 2 * 60, target: [seaurchin]};
                }
                else if(!this.slowed.target.find(x => x == seaurchin)) {
                    this.energy -= 20;
                    this.slowed.target.push(seaurchin)
                }
    
                this.slowed.time = 0;
            }
        });

        
        if(this.slowed) {
            ++this.slowed.time;

            if(this.velocity > this.maxSpeed * .5) {
                this.velocity = this.maxSpeed * .5;
            }

            if(this.slowed.time > this.slowed.duration)
                this.slowed = undefined;
        }

        this.position.move(this.velocity, this.angle);
        this.skeleton.tick();
        this.eyes.forEach(eye => eye.tick());
    }

    draw(g) {
        this.colors[1].r = this.r;
        this.colors[1].g = this.g;
        this.colors[1].b = this.b;
        //this.skeleton.draw(g);
        this.draw_skin(g);
        //this.brain.draw(g);

        if(this.energy > 0) {
            g.bar({x: this.position.x - 20, y: this.position.y - this.size - 20}, 40, 14, "rgb(250, 250, 250, .5)");
            g.bar({x: this.position.x - 18, y: this.position.y - this.size - 18}, 0.38 * this.energy, 10, "rgb(0, 250, 0, .5)");
        }

        //g.line(this.position, this.position.circulation(100, this.targetAngle.value.value), "rgb(0, 0, 0, 1)")
        this.eyes.forEach(eye => eye.draw(g));
    }
    

    draw_skin (g) {
        Fish.vertices.forEach((vertex, i) =>{
            
            let points = [this.skeleton.points[vertex[0]], this.skeleton.points[vertex[1]], this.skeleton.points[vertex[2]]];
            let s = g.shading(points[0], points[1], points[2]);
            let c = this.colors[vertex[3]];
        
            g.triangle(
                points[0].position,
                points[1].position,
                points[2].position,
                g.rgb(c.r * s, c.g * s, c.b * s, c.a)
            )
        });
    }

    calculateEnergy() {
        if(this.energy > 100)
            this.energy = 100;

        this.energy -= Math.pow(this.velocity, 2) * .01;
        this.energy -= this.smart * .01;
        this.energy -= Math.pow(this.size, 3) /800000;
        this.energy -= this.turn_speed * .02;
        this.energy -= Math.pow(this.vision, 2) * 0.000001;
        

        if(this.energy < 0) {
            this.energy = 0;
            this.alive = false;
        }
    }

    speedUp() {

        this.velocity += 0.05
        if(this.velocity > this.maxSpeed)
            this.velocity = this.maxSpeed;
    }

    eating() {
        
        if(this.eatTimer.value < this.eatTimer.threshold)
            ++this.eatTimer.value;

        

        Fish.objects.forEach(fish => {

            if(fish != this) {
                let distance = this.position.distance(fish.position)
                if(distance < this.size && this.size/fish.size >= 1.3) {

                    let angle = this.position.angle(fish.position, distance);
                    
                    if(new Angle(angle).distance(this.brain.currentDecision) < Math.PI/4) {
                        
                        fish.alive = false;
                        this.foodCount += fish.foodCount + 3;
                        this.energy += 40;
                    }
                }
            }
        });

        Food.objects.forEach(food => {
            let distance = food.position.distance(this.position);

            if(distance < this.size + food.size) {
                let angle = this.position.angle(food.position, distance);
                
                    this.velocity = 0;
                    
                    if(this.eatTimer.value >= this.eatTimer.threshold) {
                        food.eat(this)
                        this.eatTimer.value = 0;
                    }
            }
        });

        if(this.foodCount >= 5 * this.size/27.5) {
            this.foodCount -= 5 * this.size/27.5;
            Egg.objects.push(new Egg(this));
        }
    }
    
    calculate_targetAngle() {

        let max = Math.max(...this.brain.values);

        let choices = [];
        this.brain.values.forEach((value, i) => {
            if(value == max) {
                choices.push(i);
            }
        });

        let choice = [choices[0], this.targetAngle.value.distance(2 * Math.PI * choices[0]/this.brain.n)];
        for(let i = 1; i < choices.length; ++i) {

            let angle = this.targetAngle.value.distance(2 * Math.PI * choices[i]/this.brain.n);
            
            if(angle < choice[1])
                choice = [choices[i], angle];

        }
        
        this.brain.currentDecision = choice[0];
        this.brain.currentAngle = 2 * Math.PI * choice[0]/this.brain.n;

        if(this.angle.distance(this.brain.currentAngle) > this.turn_speed)
            this.angle.value += this.turn_speed * this.angle.direction(this.brain.currentAngle);
        else this.angle.value = this.brain.currentAngle
    }

    
    wall_collision(){ // Collision with walls

        if(this.position.x < this.size + this.vision) {
            if(this.position.x < this.size) {
                this.position.x = this.size;
                if(this.targetAngle.value.value > Math.PI/2 && this.targetAngle.value.value < 3*Math.PI/2)
                    this.targetAngle.value = new Angle(3*Math.PI/2 + Math.random() * Math.PI);
            }

            for(let i = 0; i < this.brain.n; ++i) {
                let diff = (this.position.x + (this.size + this.vision) * Math.cos((Math.PI * 2 * i)/this.brain.n));

                if(diff < 0)
                    this.brain.values[i] += diff * .5;
            }
        }
        
        else if(this.position.x > canvas.width - this.size - this.vision) {
            if(this.position.x > canvas.width - this.size){
                this.position.x = canvas.width - this.size;
                if(this.targetAngle.value.value > 3*Math.PI/2 || this.targetAngle.value.value < Math.PI/2)
                    this.targetAngle.value = new Angle(Math.PI/2 + Math.random() * Math.PI);
            }

            for(let i = 0; i < this.brain.n; ++i) {
                let diff = canvas.width - this.position.x - (this.vision + this.size) * Math.cos((Math.PI * 2 * i)/this.brain.n);

                if(diff < 0)
                    this.brain.values[i] += diff * .5;
            }
        }

        if(this.position.y < this.size + this.vision) {
            if(this.position.y < this.size){
                this.position.y = this.size;
                if(this.targetAngle.value.value > Math.PI)
                    this.targetAngle.value = new Angle(Math.random() * Math.PI);
            }

            for(let i = 0; i < this.brain.n; ++i) {
                let diff = this.position.y + (this.vision + this.size) * Math.sin((Math.PI * 2 * i)/this.brain.n);

                if(diff < 0)
                    this.brain.values[i] += diff * .5;
            }
        }
        
        else if(this.position.y > canvas.height - this.size - this.vision) {
            if(this.position.y > canvas.height - this.size){
                this.position.y = canvas.height - this.size;
                if(this.targetAngle.value.value < Math.PI)
                    this.targetAngle.value = new Angle(Math.PI/2 + Math.random() * Math.PI);
            }

            for(let i = 0; i < this.brain.n; ++i) {
                let diff = canvas.height - this.position.y - (this.vision + this.size) * Math.sin((Math.PI * 2 * i)/this.brain.n);

                if(diff < 0)
                    this.brain.values[i] += diff * .5;
            }
        }
    }
    
    fish_collision() { // Collision with other fishes
        
        for(let i = Fish.objects.indexOf(this) + 1; i < Fish.objects.length; ++i) {

            let fish = Fish.objects[i]
            let d = this.position.distance(fish.position);
        
            if (d < this.size + fish.size && this.size/fish.size < 1.3 && this.size/fish.size > 1/1.3) {
                let a = fish.position.angle(this.position, d);
    
                let position = new Position(
                    ((fish.position.x + this.position.x) / 2) + (this.size + fish.size) * Math.cos(a) * .5,
                    ((fish.position.y + this.position.y) / 2) + (this.size + fish.size) * Math.sin(a) * .5);
    
                fish.position = new Position(
                    ((fish.position.x + this.position.x) / 2) + (this.size + fish.size) * Math.cos(a + Math.PI) * .5,
                    ((fish.position.y + this.position.y) / 2) + (this.size + fish.size) * Math.sin(a + Math.PI) * .5);
    
                this.position = position
            }
        }
    }

    static clear() {
        for(let i = 0; i < Fish.objects.length; ++i) {
            let fish = Fish.objects[i];
            if(fish.alive == false) {
                for(let i = 0; i < 10; ++i)
                    Particle.objects.push(new Particle(fish.position, fish.r, fish.g, fish.b))
                Fish.objects.splice(i, 1)
                --i;
            }
        }
    }

    static tick() {
        Fish.objects.forEach(fish => fish.tick());
    }

    static draw(g) {
        Fish.objects.forEach(fish => fish.draw(g));
    }

    static vertices = [
        [0, 19, 18, 1],
        [0, 18, 20, 1],
        [0, 21, 19, 1],
        [0, 6, 21, 1],
        [0, 20, 7, 1],
        [0, 7, 10, 1],
        [0, 7, 10, 1],
        [0, 11, 6, 1],
        [0, 10, 1, 1],
        [0, 1, 11, 1],
        [1, 10, 12, 1],
        [1, 13, 11, 1],
        [1, 12, 2, 1],
        [1, 2, 13, 1],
        [2, 12, 14, 1],
        [2, 15, 13, 1],
        [2, 14, 3, 1],
        [2, 3, 15, 1],
        [3, 14, 16, 1],
        [3, 17, 15, 1],
        [3, 16, 4, 1],
        [3, 4, 17, 1],
        [4, 16, 5, 1],
        [4, 5, 17, 1],
        [7, 9, 10, 0],
        [6, 11, 8, 0],
        [7, 22, 9, 0],
        [6, 8, 23, 0],
        [5, 24, 25, 0],
        [5, 26, 24, 0],
        [5, 25, 27, 0],
        [5, 27, 29, 0],
        [5, 28, 26, 0],
        [28, 30, 26, 0],
        [29, 27, 31, 0],
        [24, 26, 30, 0],
        [25, 31, 27, 0],
    ];
}



class Eye {

    light = 1;
    colors = [
        {r: 50, g: 50, b: 50},
        {r: 250, g: 250, b: 250},
        {r: 20, g: 50, b: 150},
        {r: 20, g: 150, b: 150},
        {r: 50, g: 250, b: 250},
        {r: 250, g: 250, b: 250},
        {},
    ];

    constructor(host, vertex, dx, dy) {
        this.host = host;
        this.vertex = vertex;
        this.size = host.size * .1;

        this.d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
        let a = Math.acos(dx/this.d);
        if(dy < 0)
            a *= -1;

        this.position = new Position(host.position.x + host.size * this.d * Math.cos(a), host.position.y + host.size * this.d * Math.sin(a))

        this.distance = this.d * this.host.size;
        this.angle = this.host.position.angle(this.position, this.distance);
    }

    tick() {

        this.colors[6] = {r: this.host.r, g: this.host.g, b: this.host.b}
        this.distance = this.d * this.host.size;
        this.size = this.host.size * .1 + (this.host.vision + 1) * .005;
        this.position.x = this.host.position.x + this.distance * Math.cos(this.angle + this.host.angle.value)
        this.position.y = this.host.position.y + this.distance * Math.sin(this.angle + this.host.angle.value)
    }

    draw(g) {
        
        let vertex = Fish.vertices[this.vertex];
        this.colors.forEach(color => color.rgb = g.rgb(color.r * this.s, color.g * this.s, color.b * this.s))
        this.s = g.shading(this.host.skeleton.points[vertex[0]], this.host.skeleton.points[vertex[1]], this.host.skeleton.points[vertex[2]]);
        g.circle(this.position, this.size, this.colors[0].rgb);
        g.circle(this.position.circulation(this.size * .5, Math.PI + this.host.angle.value), this.size, this.colors[0].rgb);
        g.circle(this.position, this.size * .8, {rgb: this.colors[1].rgb});
        g.circle(this.position.circulation(this.size * .5, Math.PI + this.host.angle.value), this.size * .8, this.colors[2].rgb);
        g.circle(this.position, this.size * .6, this.colors[3].rgb);
        g.circle(this.position.circulation(this.size * .1, 0), this.size * .4, this.colors[4].rgb);
        g.circle(this.position.circulation(this.size * .5, 5 * Math.PI/4), this.size * .4, this.colors[5].rgb);
        
        if(this.host.tired)
            g.circle(this.position.circulation(this.size * .5, Math.PI + this.host.angle.value), this.size * 1.1, this.colors[6].rgb);
            
    }
}

export class Egg {

    static objects = [];

    colors = ["rgb(200, 60, 30)", "rgb(150, 10, 0)", "rgb(250, 110, 60)"]
    constructor(fish) {
        this.parent = fish;
        this.size = 7*fish.size/27.5;
        let a = fish.angle.value - Math.PI;
        this.position = new Position(fish.position.x + Math.cos(a) * fish.size * .4, fish.position.y + Math.sin(a) * fish.size * .4);

    }

    draw(g) {
        g.circle(this.position, this.size, this.colors[1])
        g.circle(this.position, this.size * .8, this.colors[0])
        g.circle({x: this.position.x - this.size * .2, y: this.position.y  - this.size * .2}, this.size * .5, this.colors[2])
    }

    static draw(g) {
        Egg.objects.forEach(obj => obj.draw(g));
    }

    static hatch() {
        
        Egg.objects.forEach(egg => {

            let properties = {}
            Fish.properties.forEach(property => {
                let prop = property[0];
                properties[prop] = 0
                let range = (property[2] - property[1])/10;
                properties[prop] = egg.parent[prop] + signedRandom() * range;

                if(properties[prop] > property[2])
                    properties[prop] = property[2];
                else if(properties[prop] < property[1])
                    properties[prop] = property[1];
            });

            Fish.objects.push(new Fish(new Position(egg.position.x, egg.position.y), properties));
        });

        Egg.objects = [];
    }

}


function signedRandom() {
    return Math.sign(Math.random() - .5);
}