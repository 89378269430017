
const light = normalize([1, 1, -1]);



function normalize(v) {

    let k = 0;
    v.forEach(x => {k += Math.pow(x, 2)});
    let l = Math.sqrt(k);
    let newV = [];
    v.forEach(x => {newV.push(x/l)});
    return newV;
}

export class Color {
    constructor(r, g, b, a = 1, extended) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
        this.rgb = "rgb("+ r + "," + g + "," + b + "," + a + ")";
        if(extended) {
            this.sh = "rgb("+ (r - 50) + "," + (g - 50) + "," + (b - 50) + "," + a + ")";
            this.hl = "rgb("+ (r + 50) + "," + (g + 50) + "," + (b + 50) + "," + a + ")";
        }
    }
}

export class Position {
    constructor(x, y, z = 0) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

	add(x, y) {
		return new Position(this.x + x, this.y + y);
	}

	move(velocity, angle) {
		this.x += velocity * Math.cos(angle.value);
		this.y += velocity * Math.sin(angle.value);
	}

    distance(position) {
        return Math.sqrt(Math.pow(this.x - position.x, 2) + Math.pow(this.y - position.y, 2));
    }

    circulation(radius, angle) {
        if(angle.value != undefined)
            angle = angle.value

        return new Position(this.x + radius * Math.cos(angle), this.y + radius * Math.sin(angle));
    }

    angle(position, distance = Math.sqrt(Math.pow(this.x - position.x, 2) + Math.pow(this.y - position.y, 2))) {
        
		if(distance == 0)
			return 0;
			
        let angle = Math.acos((position.x - this.x)/distance);
        if(position.y < this.y)
            angle = Math.PI * 2 - angle;
			
        return angle;
    }

    signed_angle(position) {

        let distance = Math.sqrt(Math.pow(this.x - position.x, 2) + Math.pow(this.y - position.y, 2));
        let angle = Math.acos((position.x - this.x)/distance);
        if(position.y < this.y)
            angle *= -1;
        return angle;
    }

    move(velocity, angle) {
        this.x += velocity * Math.cos(angle.value);
        this.y += velocity * Math.sin(angle.value);
    }
}

export class Angle {
    constructor(value) {
        this.value = value;
        this.normalize();
    }

    turn(value) {
        this.value += value;
        this.normalize();
    }

    normalize() {
        while(this.value > Math.PI * 2)
            this.value -= Math.PI * 2;

        while(this.value < 0)
            this.value += Math.PI * 2;
    }

    set(value) {
        this.value = value;
        this.normalize();
    }

    add(value) {
        return new Angle(this.value + value);
    }

    direction(angle) {
        while(angle < 0)
            angle += Math.PI * 2;
        while(angle > Math.PI * 2)
            angle -= Math.PI * 2;

        if(Math.abs(this.value - angle) > Math.PI)
            return Math.sign(this.value - angle);
        else return -Math.sign(this.value - angle);
    }

    distance(angle) {

        let d = Math.abs(this.value - angle);

        if(d > Math.PI) 
            d = Math.PI * 2 - d;
        return d;

    }
}

export class Graphics {
	constructor() {
		this.ctx = document.getElementById('canvas').getContext('2d');
	}
	
	rgb(r, g, b, a = 1) {
		return "rgb(" + r + "," + g + "," + b + "," + a + ")";
	}
	
	shading(p1, p2, p3) {

		let A = {x: p2.position.x - p1.position.x, y: p2.position.y - p1.position.y, z: p2.position.z - p1.position.z};
		let B = {x: p3.position.x - p1.position.x, y: p3.position.y - p1.position.y, z: p3.position.z - p1.position.z};
		let N = normalize([A.y * B.z - A.z * B.y, A.z * B.x - A.x * B.z, A.x * B.y - A.y * B.x]);
	
		return (Math.cos(Math.acos(N[0] * light[0] + N[1] * light[1] + N[2] * light[2]/((Math.pow(N[0], 2) + Math.pow(N[1], 2) + Math.pow(N[2], 2)) * (Math.pow(light[0], 2) + Math.pow(light[1], 2) + Math.pow(light[2], 2))))) + 1) * 1/2 + .4;
		
	}

	rectangle(position, width, height, color) {
		this.ctx.fillStyle = color;
		this.ctx.fillRect(position.x, position.y, width, height);
	}

	border(position, width, height, color) {
		this.ctx.fillStyle = color;
		this.ctx.strokeRect(position.x, position.y, width, height);
	}
	
	
	bar(position, width, height, color) {
		this.ctx.beginPath();
		this.ctx.arc(position.x, position.y + height/2, height/2, Math.PI/2, 3*Math.PI/2);
		this.ctx.arc(position.x + width, position.y + height/2, height/2, 3*Math.PI/2, Math.PI/2);
		this.ctx.fillStyle = color;
		this.ctx.fill();
	}

	image(position, s, ref) {
		var img = document.getElementById(ref);

		this.ctx.drawImage(img, position.x, position.y, img.width * s, img.height * s);
	}

	
  
	text(position, size, sentence, color, center) {
		this.ctx.font = size + "px Arial";
		this.ctx.fillStyle = color;
		let margin = 0;

		if(center)
			margin = this.ctx.measureText(sentence).width/2;

		this.ctx.fillText(sentence, position.x - margin, position.y);
	}

	circle(position, radius, color) {
		this.ctx.beginPath();
		this.ctx.arc(position.x, position.y, radius, 0, 2 * Math.PI);
		this.ctx.fillStyle = color;
		this.ctx.fill();
	}

	dashed_ring(position, s, t, c, n = 10) {

		this.ctx.beginPath();
	
		let step = 2 * (Math.PI)/n;
		let r1 = 0;
		let r2 = step/2;
		
		for(let i = 0; i < n; ++i) {
			this.ctx.moveTo(position.x + Math.cos(r1) * s, position.y + Math.sin(r1) * s)
			this.ctx.arc(position.x, position.y, s, r1, r2);
			r1 += step;
			r2 += step;
		}

		this.ctx.lineWidth = t;
		this.ctx.strokeStyle = c;
		this.ctx.stroke();
	}

	ring(position, s, t, c) {
		this.ctx.beginPath();
		this.ctx.arc(position.x, position.y, s + t/2, 0, 2 * Math.PI);
		this.ctx.lineWidth = t;
		this.ctx.strokeStyle = c;
		this.ctx.stroke();
	}

	triangle(p1, p2, p3, color) {
		this.ctx.beginPath();
		this.ctx.moveTo(p1.x, p1.y);
		this.ctx.lineTo(p2.x, p2.y);
		this.ctx.lineTo(p3.x, p3.y);
		this.ctx.closePath();
		this.ctx.fillStyle = color;
		this.ctx.fill();
	}

	line(position_start, position_end, color, t = 4) {
		this.ctx.beginPath();
		this.ctx.moveTo(position_start.x, position_start.y);
		this.ctx.lineTo(position_end.x, position_end.y);
		this.ctx.lineWidth = t;
		this.ctx.strokeStyle = color;
		this.ctx.stroke();
	}

	star(position, outerRadius, innerRadius, c) {

		this.circle(position, outerRadius * 1.1, "rgb(250, 250, 250, .2)");
		innerRadius = outerRadius * innerRadius;
		this.ctx.beginPath();
		this.ctx.moveTo(position.x + outerRadius, position.y);
		
		for(let i = 1; i <= 4; ++i) {
			let a1 = i * Math.PI * 2 / 4;
			let a2 = (i - .5) * Math.PI * 2 / 4;
			this.ctx.lineTo(position.x + innerRadius * Math.cos(a2), position.y + innerRadius * Math.sin(a2));
			this.ctx.lineTo(position.x + outerRadius * Math.cos(a1), position.y + outerRadius * Math.sin(a1));
		}
	
	
		this.ctx.fillStyle = c;
		this.ctx.fill();
	}

	drawStar(position, spikes, outerRadius, innerRadius, color) {
	
		var rot = Math.PI / 2 * 3;
		var x = position.x;
		var y = position.y;
		var step = Math.PI / spikes;
	
		this.ctx.beginPath();
		
		this.ctx.moveTo(position.x, position.y - outerRadius)
	
		for (let i = 0; i < spikes; i++) {
			x = position.x + Math.cos(rot) * outerRadius;
			y = position.y + Math.sin(rot) * outerRadius;
			this.ctx.lineTo(x, y)
			rot += step
	
			x = position.x + Math.cos(rot) * innerRadius;
			y = position.y + Math.sin(rot) * innerRadius;
			this.ctx.lineTo(x, y)
			rot += step
		}

		this.ctx.lineTo(position.x, position.y - outerRadius)
		this.ctx.closePath();
		this.ctx.lineWidth=1;
		this.ctx.strokeStyle=color;
		this.ctx.stroke();
		this.ctx.fillStyle=color;
		this.ctx.fill();
	}
}